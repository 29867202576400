import React from 'react';
import T from 'prop-types';
import { getAriaDescribedBy } from './helpers';

/** All other props will be spread to the <input> element */
const Input = (props) => {
  const { value, error, removeFocus, classes, name, onBlur, ...rest } = props;
  return (
    <div className={classes}>
      <input
        {...rest}
        onBlur={(e) => {
          removeFocus();
          onBlur(e);
        }}
        defaultValue={value}
        {...getAriaDescribedBy(error, name)}
        name={name}
        id={name}
      />
      {error}
    </div>
  );
};

Input.propTypes = {
  /** Used for name and id property. */
  name: T.string.isRequired,
  /** Used for wrapping <div> classnamae. */
  classes: T.string,
  /**  Error message. */
  error: T.node,
  /** Used for defaultValue prop (react) */
  value: T.oneOfType([T.string, T.number]),
};

export default Input;
