import React from 'react';
import * as Render from '../../platformui/Base/Render';
import BuildInfo from '../../platformui/BuildInfo';
import AccessibilityNav from '../../platformui/AccessibilityNav';
import Header from '../../platformui/Header';
import Footer from '../../platformui/Footer';
import CookiePanel from '../../platformui/CookiePanel';
import ScrollToTop from '../../platformui/ScrollToTop';
import HeaderTools from '../../components/header-tools';
import SurveyEntry from '../../platformui/SurveyEntry';
//import * as Blocks from '../../blocks';

const AccessibilityShortcuts = (props) => {
  return (
    <AccessibilityNav>
      <li>
        <a href="#content">Till sidans huvudinnehåll</a>
      </li>
      {props.PageTypeName === 'SearchPage' && (
        <li>
          <a href="#search">Till sökresultatet</a>
        </li>
      )}
    </AccessibilityNav>
  );
};

const Layout = (props) => {
  const activeSurvey = props.SurveyEntries?.find(
    (survey) =>
      new Date(survey?.StartDate) < new Date() &&
      new Date(survey?.StopDate) > new Date()
  );

  return (
    <div>
      <AccessibilityShortcuts key="shortcuts" {...props} />
      <BuildInfo key="buildinfo" BuildInfo={props.BuildInfo} />
      <Header
        {...props}
        key="header"
        Logo={props.Logo}
        Tools={HeaderTools}
        HasSearch={true}
      />
      <div key="mainWrapper" className="main-wrapper">
        {activeSurvey && <SurveyEntry {...activeSurvey} />}
        <props.PageTypeRenderer key="asyncpages" {...props} />
        <ScrollToTop key="scrolltop" {...props} />
      </div>
      <Footer
        key="footer"
        {...props}
        siteFooterLinkListModifiers={{
          'c-linklist--no-icons': true,
        }}
        Modifiers={{ 'c-linklist--white': true }}
        ShowLogo={false}
      />
      {!props.CookiePro.Enabled && (
        <CookiePanel key="cookie-panel" {...props} />
      )}
    </div>
  );
};

export default Render.Root(Layout);
