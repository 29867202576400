import React from 'react';
import RhbLogo from './rhb';
import VhbLogo from './vhb';

const Logo = ({ Site, logosr }) => {
  const siteLogo = Site === 'rhb' ? <RhbLogo /> : <VhbLogo />;

  return (
    <a className="c-header__logo" href="/">
      <span className="show-for-sr" data-cy="ui-header-logosr">
        {logosr}
      </span>
      {siteLogo}
    </a>
  );
};

export default Logo;
