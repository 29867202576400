import React, { useState } from 'react';
import classNames from 'classnames';
import { bool, oneOf, string, object, number, oneOfType } from 'prop-types';
import Error, { propTypes as errorPropTypes } from './error';

import Select from './select';
import Input from './input';
import Textarea from './textarea';

/**
 * Will spread all non-specified props (...rest) to the `input` element.
 *
 * Includes the `<Error>` component
 */
const Field = (props) => {
  const [isFocus, setIsFocus] = useState(false);

  const {
    className,
    disabled,
    component,
    classNameModifiers,
    isValid,
    isTouched,
    label,
    errorType,
    errorMessages,
    name,
    ...rest
  } = props;

  const classes = classNames(className, {
    [`${className}--is-error`]: !isValid && isTouched,
    [`${className}--is-valid`]: isValid && isTouched,
    [`${className}--disabled`]: disabled,
    [`${className}--has-focus`]: isFocus,
    ...classNameModifiers,
  });

  let ErrorComponent = (
    <Error
      isError={!isValid && isTouched}
      label={label}
      errorType={errorType}
      errorMessages={errorMessages}
      name={name}
    />
  );

  if (component === 'input') {
    return (
      <Input
        onFocus={() => setIsFocus(true)}
        removeFocus={() => setIsFocus(false)}
        disabled={disabled}
        error={ErrorComponent}
        classes={classes}
        name={name}
        {...rest}
      />
    );
  }
  if (component === 'textarea') {
    return (
      <Textarea
        onFocus={() => setIsFocus(true)}
        removeFocus={() => setIsFocus(false)}
        disabled={disabled}
        className={className}
        error={ErrorComponent}
        classes={classes}
        name={name}
        {...rest}
      />
    );
  }
  if (component === 'select') {
    return (
      <Select
        onFocus={() => setIsFocus(true)}
        removeFocus={() => setIsFocus(false)}
        disabled={disabled}
        error={ErrorComponent}
        classes={classes}
        name={name}
        {...rest}
      />
    );
  }
  return null;
};

const propTypes = {
  component: oneOf(['input', 'textarea', 'select']),
  /** Base classname*/
  className: string,
  /** classnames compatible object */
  classNameModifiers: object,
  /** Use `false` to display error message - if isTouched is true */
  isValid: bool,
  /** Use `true` to indicate that element is touched and error messages can be displayed */
  isTouched: bool,
  /** Not used */
  isFocus: bool,
  /** Used as `defaultValue` for input element */
  value: oneOfType([string, number]),
  /** input element id */
  name: string,
  ...errorPropTypes,
};

Field.propTypes = propTypes;

export default Field;
