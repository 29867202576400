import React from 'react';
import { callIfFunction } from '../Helpers/helpers';
import { getAriaDescribedBy } from './helpers';

const Select = (props) => {
  const { classes, error, removeFocus, onBlur, children, name, ...rest } =
    props;

  return (
    <div>
      <div className={classes}>
        <select
          name={name}
          onBlur={(e) => {
            callIfFunction(removeFocus);
            callIfFunction(onBlur, e);
          }}
          {...getAriaDescribedBy(error, name)}
          {...rest}
        >
          {children}
        </select>
      </div>
      {error}
    </div>
  );
};

export default Select;
