import React from 'react';
import { bool, shape, string, node } from 'prop-types';

/**
 * Error message component
 * 
```
<Error
  isError={true}
  errorMessages={{ prefix: 'Fel!', warning: 'Det här är felmeddelandet.' }} 
  label="500" 
  errorType="warning" 
/>
```
 * 
 */
const Error = (props) => {
  if (!props.isError) return null;

  return (
    <div className="c-forms__error" id={`${props.name}-error`}>
      {props.errorMessages && props.errorMessages['prefix'] && (
        <strong>{props.errorMessages['prefix']} </strong>
      )}
      {props.label && (
        <span className="c-forms__error__label">{props.label}.</span>
      )}
      {props.errorMessages &&
        props.errorMessages[props.errorType] &&
        props.errorMessages[props.errorType]}
    </div>
  );
};

export const propTypes = {
  /** If `true` display error message */
  isError: bool,
  /** Label is displayed before the error message */
  label: node,
  /** errorType must be a string with the name of a key that exists in `errorMessages` */
  errorType: string,
  /** errorMessages must contain a key with the the errorType */
  errorMessages: shape({
    prefix: node,
  }),
};

Error.propTypes = propTypes;

export default Error;
