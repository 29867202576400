import React, { useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { getAriaDescribedBy } from './helpers';

const Textarea = (props) => {
  const [charsLeft, setCharsLeft] = useState(
    props.maxLength && props.value
      ? props.maxLength - props.value.length
      : props.maxLength
  );

  const textarea = useRef();

  function getValue() {
    return textarea.current.value;
  }

  function getCharsLeft() {
    return props.maxLength ? props.maxLength - getLength() : null;
  }

  function getLength() {
    return getValue().length;
  }

  useEffect(() => {
    setCharsLeft(getCharsLeft());
  }, []);

  function handleKeyUp() {
    setCharsLeft(getCharsLeft());
  }

  const {
    value,
    className,
    classes,
    removeFocus,
    disabled,
    children,
    onBlur,
    maxLength,
    error,
    maxLengthTranslation,
    name,
    ...rest
  } = props;

  const charsClasses = classNames({
    [`${className}__chars`]: true,
    [`${className}__chars--disabled`]: disabled,
  });

  return (
    <div>
      {children}
      {maxLength && (
        <span className={charsClasses}>
          {charsLeft} {maxLengthTranslation}
        </span>
      )}
      <div className={classes}>
        <textarea
          ref={textarea}
          defaultValue={value}
          disabled={disabled}
          {...getAriaDescribedBy(error, name)}
          name={name}
          id={name}
          onBlur={(e) => {
            removeFocus();
            onBlur(e);
          }}
          onKeyUp={() => {
            handleKeyUp();
          }}
          {...rest}
        />
      </div>
      {error}
    </div>
  );
};

export default Textarea;
