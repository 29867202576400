import React, { Fragment, createRef } from 'react';
import classNames from 'classnames';
import withToolbarButtonList from '../../platformui/Containers/withToolbarButtonList';
import Field from '../../platformui/Forms/field';

const RegionPanel = (props) => {
  const regionform = createRef();
  const handleChange = () => {
    regionform.current.submit();
  };

  const getSelectedItem = () => {
    const { Regionalization } = props;
    const { Regions } = Regionalization;

    const selectedValue = Regions.Options.find(
      (item) => item.Selected === true
    );

    if (selectedValue) {
      return selectedValue;
    }

    return null;
  };

  const { ButtonSVG, Regionalization, isVisible } = props;
  if (!Regionalization) return null;

  const { Regions } = Regionalization;
  if (!Regions) return null;

  if (!Regionalization && !Regions) return null;
  if (!Regionalization.Enable) return null;

  const selectedItem = getSelectedItem();
  const selectedValue = selectedItem ? selectedItem.Value : '';

  const regionPanelClasses = classNames({
    'c-region-panel': true,
    'c-region-panel--is-opened': props.isVisible,
  });

  const regionWrapper = classNames({
    'c-region-wrapper': true,
    'c-region-wrapper--selected': selectedItem,
  });

  return (
    <Fragment>
      <div className={regionWrapper}>
        {selectedItem && (
          <div className="c-region-panel__selected">
            <span>
              {Regionalization.SelectedRegionText}:{' '}
              <strong>{selectedItem.Name}</strong>
            </span>
          </div>
        )}
        {ButtonSVG && (
          <a
            href="#region-panel"
            tabIndex="0"
            role="button"
            className="c-header-tools__toolbtn c-header-tools__toolbtn--region"
            onClick={(e) => props.handleVisible(e, 'region-panel')}
            aria-controls="region-panel"
            aria-labelledby="description-region"
            aria-expanded={isVisible}
          >
            {ButtonSVG}
            <span id="description-region">
              {Regionalization.ButtonText && Regionalization.ButtonText}
            </span>
          </a>
        )}
      </div>
      <div
        id="region-panel"
        className={regionPanelClasses}
        aria-hidden={!isVisible}
      >
        <div className="row">
          <div className="columns">
            <div className="c-region-panel__inner">
              <div
                className="c-region-panel__editorial"
                dangerouslySetInnerHTML={{ __html: Regionalization.Text }}
              />
              <form
                method="get"
                className="c-region-panel__form"
                ref={regionform}
              >
                <fieldset>
                  <legend className="show-for-sr">
                    {Regions.Label && Regions.Label}
                  </legend>
                  <div className="c-region-panel__dropdown">
                    <Field
                      component="select"
                      name={Regions.Name}
                      className="c-forms__select"
                      isTouched={Regions.Touched}
                      value={selectedValue}
                      onChange={(e) => handleChange(e)}
                    >
                      <option disabled>{Regions.Label && Regions.Label}</option>
                      <option value={Regions.DefaultOption.Value}>
                        {Regions.DefaultOption.Label &&
                          Regions.DefaultOption.Label}
                      </option>
                      {Regions.Options.length > 0 &&
                        Regions.Options.map((region, index) => {
                          return (
                            <option key={index} value={region.Value}>
                              {region.Name}
                            </option>
                          );
                        })}
                    </Field>
                  </div>
                  {Regions.Label && (
                    <button className="show-for-sr" type="submit">
                      {Regions.Label}
                    </button>
                  )}
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default withToolbarButtonList(RegionPanel);
