const ArticlePage = async () =>
  await import('./article').then((page) => page.default);
const StartPage = async () =>
  await import('./start').then((page) => page.default);
const SectionStartPage = async () =>
  await import('./sectionstart').then((page) => page.default);
const CollectionPage = async () =>
  await import('./collection').then((page) => page.default);
const SearchPage = async () =>
  await import('./search').then((page) => page.default);
const ErrorPage = async () =>
  await import('./error').then((page) => page.default);
const ReferencePage = async () =>
  await import('./reference').then((page) => page.default);
const SelfTestPage = async () =>
  await import('./selftest').then((page) => page.default);

export const importPage = (pageTypeName) => {
  switch (pageTypeName) {
    case 'ArticlePage':
      return ArticlePage();
    case 'StartPage':
      return StartPage();
    case 'SectionStartPage':
      return SectionStartPage();
    case 'CollectionPage':
      return CollectionPage();
    case 'ReferencePage':
      return ReferencePage();
    case 'SelfTestPage':
      return SelfTestPage();
    case 'SearchPage':
      return SearchPage();
    case 'ErrorPage':
      return ErrorPage();
    default:
      return StartPage();
  }
};
