import React from 'react';
import GlobalSearch from '../../platformui/Search/globalsearch';
import RegionPanel from '../region-panel';
import ReadingAid from '../../platformui/ReadingAid';
import Spinner from '../../platformui/Spinner';

const RhbTools = (props) => {
  return (
    <ul className="c-header-tools">
      <li>
        <RegionPanel
          {...props}
          ButtonSVG={
            <i className="c-header-tools__icon c-header-tools__icon--region"></i>
          }
        />
      </li>
      <li>
        <ReadingAid
          {...props}
          Spinner={Spinner}
          ButtonSVG={
            <i className="c-header-tools__icon c-header-tools__icon--reading-aid"></i>
          }
          toggleButtonClasses={[
            'c-header-tools__toolbtn',
            'c-reading-aid__toggle-panel',
          ].join(' ')}
          activateButtonClass={'c-reading-aid__activatebtn'}
        />
      </li>
      <li>
        <GlobalSearch
          {...props}
          ButtonSVG={
            <i className="c-header-tools__icon c-header-tools__icon--search"></i>
          }
        />
      </li>
    </ul>
  );
};

export default RhbTools;